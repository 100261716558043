@import '../../../../libs/veneer/src/styles/sass/application';

.container {
  height: 100vh;
  @extend %page-start;
  background-color: var(--c--light-grey);
}

.inner {
  @extend %grid;
}

.title {
  @include property-on-screens('grid-column', span 6, span 6, span 8);
  padding-bottom: 3.8rem;
  @extend %heading-2;
}

.popular-searches {
  grid-column: 1/ -1;
}
