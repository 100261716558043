@import '../../../../libs/veneer/src/styles/sass/application';

.cta {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;

  &__search {
    @include property-on-screens('width', 100%, 40vw, 32vw);
  }
}

.popular-searches {
  padding: 4.5rem 0;

  &__title {
    padding-bottom: 1.6rem;
    @extend %subheading;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
